import React from "react";
import "../style/style.scss";
import { useTranslation } from "react-i18next";
import NavBar from "../components/common/navbar";
import { email } from "../constants/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { Helmet } from "react-helmet";

/**
 * Contact page
 */
export const ContactPage = () => {
  const { t, i18n } = useTranslation();
  const copyText = () => {
    navigator.clipboard.writeText(email);
    toast("Copied!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
    });
  };
  const subtitleStyle = {
    fontSize: "12px",
  };
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ask us questions or share your suggestions with Pix</title>
        <meta
          name="description"
          content="Please feel free to contact us if you want to share feedback or have any suggestions for this tool."
        ></meta>
      </Helmet>

      <NavBar />
      <main className="container" role="main">
        <div className="container">
          <h1 className="mt-5">{t("content.contactPage.title")}</h1>
          <article className="pix-page-default-content">
            <p className="lead">{t("content.contactPage.body")}</p>
            <div>
              <button
                title="copy to clipboard"
                className="btn-dark rounded-3 "
                onClick={copyText}
              >
                {email}
              </button>
              <div>
                <small style={subtitleStyle} className="text-muted">
                  {t("buttons.clickToCopy")}
                </small>
              </div>
            </div>
            <div className="mt-5">
              <a href={`/${i18n.language}/`}>Back</a>
            </div>
          </article>
        </div>
      </main>
      <ToastContainer />
    </React.Fragment>
  );
};

export default ContactPage;
